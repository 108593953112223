import { api } from '@/helpers/api'
import { commonUtils } from '@/helpers/commonUtils'
import { idleCallback } from '@/helpers/IdelCallbackUtil'
import { storageHelper } from '@/helpers/storageHelper'
import { throttle } from 'lodash'
import { userService } from '../userService'
import { TranslateServiceConsts as Consts } from './consts'
import { TranslateServiceType as Type } from './serviceTypes'
import { user } from '@/helpers/api/user'

type Locale = Type.Locale
type Paragraph = Type.Paragraph
type TranslateResult = Type.TranslateResult
type LocaleWithAutoDetect = Type.LocaleWithAutoDetect
type TriggerType = PostDataTypes.UseType

interface ParagraphGroup {
  merged: boolean
  mergedText: string
  from: LocaleWithAutoDetect
  to: Locale
  paragraphs: Array<Paragraph>
  textType: 'html' | 'plain'
}

class TranslateService {
  protected storagePrefix = ''
  private localeList: Type.LocaleList = []
  private db!: IDBDatabase
  private imgDb!: IDBDatabase

  private translatedTextCacheMap: {
    [key: string]: string
  } = {}
  private translatedImageCacheMap: {
    [key: string]: string
  } = {}

  private updateUsageInfoThrottleCal = throttle(
    () => {
      userService.updateUserInfo()
    },
    5000,
    { leading: true }
  )

  private APP_VERSION = '1.0.0'
  private APP_VERSION_KEY = 'app_version'
  private CLEAR_CACHE_ON_UPDATE = true

  // 网页对照翻译、PDF 翻译、悬浮翻译、字幕翻译
  public async translate(
    {
      paragraphs,
      taskUid,
      originTitle,
      originUrl,
      triggerType,
      detectLang,
      shareHashId,
      fileId,
      context,
    }: {
      paragraphs: Array<Paragraph>
      taskUid: string
      originUrl: string
      originTitle: string
      triggerType: TriggerType
      detectLang?: Type.Locale
      shareHashId?: string
      fileId: number
      context?: string
    },
    callback: (results: Array<TranslateResult>) => void
  ): Promise<void> {
    if (!paragraphs || paragraphs.length <= 0) {
      callback([])
      return
    }
    // deepl 翻译引擎需要标点符号后面加空格
    paragraphs.forEach((p) => {
      p.text = this.addWhiteSpace(p.text, p.links)
    })
    const paragraphGroups = this.mergeParagraph(paragraphs)
    await this.translateParagraphGroups({
      originTitle,
      originUrl,
      paragraphGroups,
      taskUid,
      triggerType,
      detectLang,
      shareHashId,
      fileId,
      context,
      callback,
    })
  }

  public async getLocaleList(): Promise<Type.LocaleList> {
    if (this.localeList.length === 0) {
      await this.initLocaleList()
    }
    return this.sortLocale(this.localeList)
  }

  public async clearTranslateCache() {
    this.translatedTextCacheMap = {}
    storageHelper.set({ translatedTextCacheMap: {} })
  }

  public async init() {
    this.initLocaleList()
    await this.initIndexedDB()
    await this.initImgIndexedDB()
    await this.checkVersionAndClearCacheIfNeeded()
    await this.loadStorageData()
    await this.loadImageStorageData()
  }

  private async initLocaleList() {
    let allLocales: {
      [key: string]: { name: string; nativeName: string; chineseName?: string }
    } = {}

    try {
      const { language_list } = await user.getLanguageList()
      const keys = Object.keys(language_list)
      if (keys && keys.length > 0) {
        allLocales = language_list
      }
    } catch (error) {
      allLocales = Consts.ALL_LOCALES
    }
    this.localeList = []
    Object.keys(allLocales).forEach((key) => {
      const item = allLocales[key]
      this.localeList.push({
        locale: key,
        name: item['name'],
        localName: `${item['chineseName'] ? item['chineseName'] + ' ' : ''}${item['nativeName']}`,
        chineseName: item.chineseName || item.nativeName,
      })
    })
  }

  private sortLocale(_languages: Type.LocaleList): Type.LocaleList {
    const defaultLocaleSortList = [
      'zh-Hans',
      'zh-Hant',
      'en',
      'ja',
      'ko',
      'es',
      'de',
      'fr',
      'fr-CA',
      'pt',
      'pt-PT',
      'ru',
    ]
    const languages = [..._languages]
    const sortLanguages: Type.LocaleList = []
    defaultLocaleSortList.forEach((locale) => {
      const targetLocale = languages.find((l) => l.locale === locale)
      const targetLocaleIndex = languages.findIndex((l) => l.locale === locale)
      if (targetLocale) {
        sortLanguages.push(targetLocale)
        languages.splice(targetLocaleIndex, 1)
      }
    })
    return sortLanguages.concat(languages)
  }

  private async translateByXunFeiTranslate({
    options,
    triggerType,
    callback,
  }: {
    options: PostDataTypes.TranslateOptions
    triggerType: TriggerType
    callback: (err: string | null, results: Array<ServerDataTypes.AiTranslateResult>) => void
  }) {
    const normalizedOptions = this.normalizeOptionsLocale(options)
    try {
      const { respContentList } = await api.pdf.translate({
        ...normalizedOptions,
        useType: triggerType,
      })
      callback(
        null,
        respContentList.map((data, index) => {
          return {
            data,
            index,
          }
        })
      )
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // api.recordError(`xunfei_${commonUtils.formatErrorMsg(error)}`, {
      //   ...normalizedOptions,
      //   useType: triggerType,
      // })
      if (error.statusCode === 400 && error.statusMessage === '找不到用户凭据') {
        userService.updateUserInfo()
      }
      callback(commonUtils.formatErrorMsg(error), [])
    }
  }

  private async translateByAiTranslate({
    options,
    triggerType,
    callback,
  }: {
    options: PostDataTypes.TranslateOptions
    triggerType: TriggerType
    callback: (err: string | null, results: Array<ServerDataTypes.AiTranslateResult>) => void
  }) {
    const normalizedOptions = this.normalizeOptionsLocale(options)
    try {
      const { taskId } = await api.pdf.aiTranslate({
        ...normalizedOptions,
        useType: triggerType,
      })
      console.time('ai_translate ')
      await this.fetchAiTranslateResult({
        taskId,
        options: normalizedOptions,
        callback,
      })
      console.timeEnd('ai_translate')
    } catch (error) {
      // api.recordError(`ai_${commonUtils.formatErrorMsg(error)}`, {
      //   ...normalizedOptions,
      //   useType: triggerType,
      //   translateType: 'ai',
      // })
      callback(commonUtils.formatErrorMsg(error), [])
    }
  }

  private async fetchAiTranslateResult({
    taskId,
    options,
    callback,
  }: {
    taskId: number
    options: PostDataTypes.TranslateOptions
    callback: (err: string | null, results: Array<ServerDataTypes.AiTranslateResult>) => void
  }) {
    try {
      const { status, data } = await api.pdf.getAiTranslateResult(taskId)
      callback(null, data)
      // NOTE: 增加最大循环次数判断
      if (status === 'running') {
        await commonUtils.asyncDelay(1 * 1000)
        await this.fetchAiTranslateResult({ taskId, options, callback })
      }
    } catch (error) {
      // api.recordError(`ai_result_${commonUtils.formatErrorMsg(error)}`, {
      //   ...options,
      //   taskId,
      // })
      callback(commonUtils.formatErrorMsg(error), [])
    }
  }

  private mergeParagraph(paragraphs: Array<Paragraph>): Array<ParagraphGroup> {
    const paragraphGroups: Array<ParagraphGroup> = []
    // 限制详见 https://learn.microsoft.com/zh-cn/azure/cognitive-services/Translator/service-limits#character-and-array-limits-per-request
    // 一段 paragraphGroup 最多包含 40 * 1000 个字符
    const maxMergedTextLength = 40 * 1000
    // 一段 paragraphGroup 最多包含 100 个 payloads
    const maxParagraphCount = 1000
    const pushNewParagraphGroup = (paragraph: Paragraph) => {
      paragraphGroups.push({
        merged: false,
        mergedText: paragraph.text,
        from: paragraph.from,
        to: paragraph.to,
        paragraphs: [paragraph],
        textType: paragraph.htmlStr ? 'html' : 'plain',
      })
    }
    paragraphs.forEach((paragraph) => {
      const matchedGroup = this.findMatchedParagraphGroup(paragraph, paragraphGroups)
      if (matchedGroup) {
        if (
          matchedGroup.paragraphs.length < maxParagraphCount &&
          matchedGroup.mergedText.length + paragraph.text.length < maxMergedTextLength
        ) {
          matchedGroup.paragraphs.push(paragraph)
          matchedGroup.mergedText += paragraph.text
        } else {
          matchedGroup.merged = true
          pushNewParagraphGroup(paragraph)
        }
      } else {
        pushNewParagraphGroup(paragraph)
      }
    })
    return paragraphGroups
  }

  private findMatchedParagraphGroup(
    paragraph: Paragraph,
    paragraphGroups: Array<ParagraphGroup>
  ): ParagraphGroup | undefined {
    return paragraphGroups.find(({ merged, from, to, textType }) => {
      const paragraphTextType = paragraph.htmlStr ? 'html' : 'plain'
      const textTypeIsMatched = paragraphTextType === textType
      return !merged && from === paragraph.from && to === paragraph.to && textTypeIsMatched
    })
  }

  private getParagraphCacheKey(p: Paragraph): string {
    const { to, from, text } = p
    const md5 = commonUtils.md5(`$ai-${to}-${from}-${text}`)
    return md5.substring(8, 24)
  }

  private async translateParagraphGroup({
    pageTitle,
    pageUrl,
    paragraphGroup,
    taskUid,
    triggerType,
    detectLang,
    shareHashId,
    fileId,
    context,
    callback,
  }: {
    paragraphGroup: ParagraphGroup
    taskUid: string
    pageTitle: string
    pageUrl: string
    triggerType: TriggerType
    detectLang?: Type.Locale
    shareHashId?: string
    fileId: number
    context?: string
    callback: (results: Array<TranslateResult>) => void
  }): Promise<void> {
    const { from, textType, to, paragraphs } = paragraphGroup
    const options: PostDataTypes.TranslateOptions = {
      fromLang: from,
      taskUid,
      textList: paragraphs.map((p) => {
        return p.htmlStr || p.text
      }),
      toLang: to,
      textType,
      pageUrl,
      pageTitle,
      translateType: 'ai',
      detectLang,
      shareHashId,
      fileId,
      context,
    }
    await this.tryTranslateByServer(options, paragraphs, triggerType, callback)
  }

  private tryGetCachedParagraphs(paragraphs: Array<Paragraph>): {
    needServiceTranslateParagraphs: Array<Paragraph>
    cachedResults: Array<TranslateResult>
  } {
    const needServiceTranslateParagraphs: Array<Paragraph> = []
    const cachedResults: Array<TranslateResult> = []
    paragraphs.forEach((p) => {
      const cachedValue = this.getCachedTranslate(p)
      if (cachedValue) {
        cachedResults.push({
          ...p,
          result: cachedValue,
          detectedLang: p.from,
          error: null,
        })
      } else {
        needServiceTranslateParagraphs.push(p)
      }
    })
    return {
      needServiceTranslateParagraphs,
      cachedResults,
    }
  }

  private async translateParagraphGroups({
    paragraphGroups,
    originTitle,
    originUrl,
    taskUid,
    triggerType,
    detectLang,
    shareHashId,
    callback,
    fileId,
    context,
  }: {
    paragraphGroups: Array<ParagraphGroup>
    taskUid: string
    originUrl: string
    originTitle: string
    triggerType: TriggerType
    detectLang?: Type.Locale
    shareHashId?: string
    fileId: number
    context?: string
    callback: (results: Array<TranslateResult>) => void
  }): Promise<void> {
    await Promise.all(
      paragraphGroups.map(async (s) => {
        return this.translateParagraphGroup({
          pageTitle: originTitle,
          pageUrl: originUrl,
          paragraphGroup: s,
          taskUid,
          triggerType,
          detectLang,
          shareHashId,
          fileId,
          context,
          callback,
        })
      })
    )
  }

  private async tryTranslateByServer(
    options: PostDataTypes.TranslateOptions,
    paragraphs: Paragraph[],
    triggerType: TriggerType,
    callback: (results: Array<TranslateResult>) => void
  ): Promise<void> {
    const { fromLang } = options
    const results: Array<TranslateResult> = []
    const { cachedResults, needServiceTranslateParagraphs } =
      this.tryGetCachedParagraphs(paragraphs)
    // 获取缓存数据
    callback(cachedResults)
    if (needServiceTranslateParagraphs.length === 0) {
      callback([])
      return
    }
    const translatedParagraphs: Array<TranslateResult> =
      needServiceTranslateParagraphs as Array<TranslateResult>

    await this.translateByServer({
      options: {
        ...options,
        textList: needServiceTranslateParagraphs.map((p) => {
          return p.htmlStr || p.text
        }),
      },
      triggerType,
      callback: (err, data) => {
        if (err) {
          const errMsg = commonUtils.formatErrorMsg(err) || '翻译失败，请稍后重试或联系客服'
          results.push(
            ...translatedParagraphs
              .filter((p) => !p.result)
              .map((p) => {
                return {
                  ...p,
                  result: '',
                  detectedLang: fromLang,
                  error: errMsg,
                }
              })
          )
          callback(results)
        } else {
          const cacheList: Array<{ p: Paragraph; cacheData: string }> = []
          results.push(
            ...data.map(({ data, index }) => {
              const p = translatedParagraphs[index]
              cacheList.push({
                p,
                cacheData: data,
              })
              return {
                ...p,
                result: data,
                detectedLang: fromLang,
                error: null,
              }
            })
          )
          // 更新缓存数据
          this.setTranslateToCache(cacheList)
          callback(results)
        }
      },
    })
  }

  private async translateByServer({
    options,
    triggerType,
    callback,
  }: {
    options: PostDataTypes.TranslateOptions
    triggerType: TriggerType
    callback: (err: string | null, results: Array<ServerDataTypes.AiTranslateResult>) => void
  }) {
    // NOTE：默认调用机器翻译接口
    const translateType: 'xunfei' | 'ai' = 'xunfei' as unknown as 'xunfei' | 'ai'
    if (translateType === 'xunfei') {
      await this.translateByXunFeiTranslate({
        options,
        triggerType,
        callback,
      })
    }
    if (translateType === 'ai') {
      await this.translateByAiTranslate({
        options,
        triggerType,
        callback,
      })
    }
  }

  private normalizeOptionsLocale(
    options: PostDataTypes.TranslateOptions
  ): PostDataTypes.TranslateOptions {
    const normalizedOptions = { ...options }
    normalizedOptions.fromLang = commonUtils.standardizeLocale(normalizedOptions.fromLang)
    if (normalizedOptions.fromLang === Consts.AUTO_DETECT_LANG) {
      normalizedOptions.fromLang = 'auto'
    }
    normalizedOptions.fromLang = commonUtils.standardizeLocale(normalizedOptions.fromLang as Locale)
    normalizedOptions.toLang = commonUtils.standardizeLocale(normalizedOptions.toLang as Locale)
    return {
      ...normalizedOptions,
    }
  }

  private loadStorageData() {
    return new Promise((resolve) => {
      console.log('load stroage data')
      // Open our object store and then get a cursor list of all the different data items in the IDB to iterate through
      const objectStore = this.db
        .transaction('translatedTextCacheMap')
        .objectStore('translatedTextCacheMap')
      objectStore.openCursor().onsuccess = (event: any) => {
        const cursor = event.target.result
        // Check if there are no (more) cursor items to iterate through
        if (!cursor) {
          // No more items to iterate through, we quit.
          console.log('Entries all displayed.')
          resolve('success')
          return
        }

        // Check which suffix the deadline day of the month needs
        const { data } = cursor.value
        if (data) {
          this.translatedTextCacheMap = data
        }
        // continue on to the next item in the cursor
        cursor.continue()
        resolve('success')
      }
    })
  }

  private loadImageStorageData() {
    return new Promise((resolve) => {
      console.log('load image storage data')
      // Open our object store and then get a cursor list of all the different data items in the IDB to iterate through
      const objectStore = this.imgDb
        .transaction('translatedImageCacheMap')
        .objectStore('translatedImageCacheMap')
      objectStore.openCursor().onsuccess = (event: any) => {
        const cursor = event.target.result
        // Check if there are no (more) cursor items to iterate through
        if (!cursor) {
          // No more items to iterate through, we quit.
          console.log('Entries all displayed.')
          resolve('success')
          return
        }

        // Check which suffix the deadline day of the month needs
        const { data } = cursor.value
        if (data) {
          this.translatedImageCacheMap = data
        }
        // continue on to the next item in the cursor
        cursor.continue()
        resolve('success')
      }
    })
  }
  private getCachedTranslate(p: Paragraph) {
    return this.translatedTextCacheMap[this.getParagraphCacheKey(p)]
  }

  // private async setTranslateToCache(
  //   list: Array<{ p: Paragraph; cacheData: string }>
  // ) {
  //   list.forEach(({ p, cacheData }) => {
  //     this.translatedTextCacheMap[this.getParagraphCacheKey(p)] = cacheData
  //   })
  //   const cachedSize = await chrome.storage.local.getBytesInUse()
  //   // 最大缓存 30 MB
  //   if (cachedSize > 30 * 1024 * 1024) {
  //     const translatedTextCacheMap: { [key: string]: string } = {}
  //     // 移除前 5w 条缓存数据
  //     Array.from(Object.keys(this.translatedTextCacheMap))
  //       .slice(50 * 1000)
  //       .forEach((key) => {
  //         translatedTextCacheMap[key] = this.translatedTextCacheMap[key]
  //       })
  //     this.translatedTextCacheMap = {
  //       ...translatedTextCacheMap,
  //     }
  //   }
  //   storageHelper.set({ translatedTextCacheMap: this.translatedTextCacheMap })
  // }

  private initIndexedDB() {
    return new Promise((resolve) => {
      // Let us open our database
      const DBOpenRequest = window.indexedDB.open('translatedTextCacheMap', 4)

      // Register two event handlers to act on the database being opened successfully, or not
      DBOpenRequest.onerror = (event) => {
        console.log('Error loading database.')
        resolve('error')
      }

      DBOpenRequest.onsuccess = async (event) => {
        // Store the result of opening the database in the db variable. This is used a lot below
        this.db = DBOpenRequest.result

        resolve('success')
      }
      DBOpenRequest.onupgradeneeded = (event: any) => {
        this.db = event.target.result

        this.db.onerror = (event) => {
          console.log('Error loading database.')
        }

        // Create an objectStore for this database
        const objectStore = this.db.createObjectStore('translatedTextCacheMap', {
          keyPath: 'key',
        })

        objectStore.createIndex('data', 'data', { unique: false })
      }
    })
  }
  private initImgIndexedDB() {
    return new Promise((resolve) => {
      // Let us open our database
      const DBOpenRequest = window.indexedDB.open('translatedImageCacheMap', 4)

      // Register two event handlers to act on the database being opened successfully, or not
      DBOpenRequest.onerror = (event) => {
        console.log('Error loading database.')
        resolve('error')
      }

      DBOpenRequest.onsuccess = async (event) => {
        // Store the result of opening the database in the db variable. This is used a lot below
        this.imgDb = DBOpenRequest.result

        resolve('success')
      }
      DBOpenRequest.onupgradeneeded = (event: any) => {
        this.imgDb = event.target.result

        this.imgDb.onerror = (event) => {
          console.log('Error loading database.')
        }

        // Create an objectStore for this database
        const objectStore = this.imgDb.createObjectStore('translatedImageCacheMap', {
          keyPath: 'key',
        })

        objectStore.createIndex('data', 'data', { unique: false })
      }
    })
  }

  private setTranslateToCache(list: Array<{ p: Paragraph; cacheData: string }>) {
    idleCallback(() => {
      if (!this.db) {
        return
      }
      list.forEach(({ p, cacheData }) => {
        this.translatedTextCacheMap[this.getParagraphCacheKey(p)] = cacheData
      })

      // 检查缓存条目数量
      const keysCount = Object.keys(this.translatedTextCacheMap).length
      const MAX_CACHE_ENTRIES = 50000 // 最大缓存条目数

      // 如果超过最大条目数，移除旧数据
      if (keysCount > MAX_CACHE_ENTRIES) {
        console.log(`缓存条目超过限制(${MAX_CACHE_ENTRIES})，正在清理文本缓存旧数据...`)
        const translatedTextCacheMap: { [key: string]: string } = {}

        // 只保留最新的MAX_CACHE_ENTRIES条数据
        const keys = Object.keys(this.translatedTextCacheMap)
        keys.slice(keys.length - MAX_CACHE_ENTRIES).forEach((key) => {
          translatedTextCacheMap[key] = this.translatedTextCacheMap[key]
        })

        this.translatedTextCacheMap = translatedTextCacheMap
      }

      // Open a read/write DB transaction, ready for adding the data
      const transaction = this.db.transaction(['translatedTextCacheMap'], 'readwrite')

      // Report on the success of the transaction completing, when everything is done
      transaction.oncomplete = () => {
        console.log('Transaction completed: database modification finished.')
      }

      // Handler for any unexpected error
      transaction.onerror = () => {
        console.log(`Transaction not opened due to error: ${transaction.error}`)
      }

      // Call an object store that's already been added to the database
      const objectStore = transaction.objectStore('translatedTextCacheMap')
      // Make a request to add our newItem object to the object store
      const objectStoreRequest = objectStore.put({
        key: 'translateText',
        data: this.translatedTextCacheMap,
      })
      objectStoreRequest.onsuccess = (event) => {
        console.log('request success')
      }
    })
  }

  private setImageTranslateToCache(list: Array<{ key: string; cacheData: string }>) {
    idleCallback(() => {
      if (!this.imgDb) {
        return
      }
      list.forEach(({ key, cacheData }) => {
        this.translatedImageCacheMap[key] = cacheData
      })

      // 检查缓存条目数量
      const keysCount = Object.keys(this.translatedImageCacheMap).length
      const MAX_CACHE_ENTRIES = 10000 // 最大缓存条目数

      // 如果超过最大条目数，移除旧数据
      if (keysCount > MAX_CACHE_ENTRIES) {
        console.log(`缓存条目超过限制(${MAX_CACHE_ENTRIES})，正在清理图片缓存旧数据...`)
        const translatedImageCacheMap: { [key: string]: string } = {}

        // 只保留最新的MAX_CACHE_ENTRIES条数据
        const keys = Object.keys(this.translatedImageCacheMap)
        keys.slice(keys.length - MAX_CACHE_ENTRIES).forEach((key) => {
          translatedImageCacheMap[key] = this.translatedImageCacheMap[key]
        })

        this.translatedImageCacheMap = translatedImageCacheMap
      }

      // Open a read/write DB transaction, ready for adding the data
      const transaction = this.imgDb.transaction(['translatedImageCacheMap'], 'readwrite')

      // Report on the success of the transaction completing, when everything is done
      transaction.oncomplete = () => {
        console.log('Transaction completed: database modification finished.')
      }

      // Handler for any unexpected error
      transaction.onerror = () => {
        console.log(`Transaction not opened due to error: ${transaction.error}`)
      }

      // Call an object store that's already been added to the database
      const objectStore = transaction.objectStore('translatedImageCacheMap')
      // Make a request to add our newItem object to the object store
      const objectStoreRequest = objectStore.put({
        key: 'translateImage',
        data: this.translatedImageCacheMap,
      })
      objectStoreRequest.onsuccess = (event) => {
        console.log('request success')
      }
    })
  }

  private addWhiteSpace(text: string, links: string[] = []): string {
    if (!links.length) {
      text = text.replace(/([.,])/g, '$1 ')
    } else {
      text = text.replace(/([,])/g, '$1 ')
    }
    return text
  }

  public async translateImage(
    params: {
      base64: string
      fromLang: string
      toLang: string
      module: PostDataTypes.FileUploadModule
      fileId?: number
    },
    callback: (errMsg: string | null, imageResult: string) => void
  ) {
    try {
      const { base64, fromLang, toLang, module } = params
      let fileId = params.fileId

      const cacheKey = commonUtils.md5(base64 + toLang)
      const cacheData = this.translatedImageCacheMap[cacheKey]
      // console.log(cacheData, this.translatedImageCacheMap)
      if (cacheData) {
        callback(null, cacheData)
        return
      }

      if (!fileId) {
        const metaDataAndBase64 = base64.split(',')
        const metaData = metaDataAndBase64[0]
        const contentType = metaData.split(';')[0].replace('data:', '')

        const base64ToFile = (base64String: string, fileName: string, contentType: string) => {
          return fetch(base64String)
            .then((res) => res.blob())
            .then((blob) => new File([blob], fileName, { type: contentType }))
        }

        const fileName = commonUtils.md5(base64)
        const file = await base64ToFile(base64, fileName, contentType)

        const {
          data: { fileId: _fileId, signUrl },
        } = await api.pdf.getUploadSignUrl({
          contentLength: file.size,
          filename: file.name,
          module,
        })

        // 上传图片到CORS
        await fetch(signUrl, {
          method: 'PUT',
          body: file,
        })
        fileId = _fileId
      }
      // 翻译
      const { translatePicImage } = await api.pdf.imageTranslate({
        fileId: fileId!,
        fromLang,
        toLang,
        useType: module === 'translate_pdf' ? 'pdf_pic_translate' : 'pic_translate',
      })
      callback(null, translatePicImage)
      // 设置到本地缓存
      this.setImageTranslateToCache([{ key: cacheKey, cacheData: translatePicImage }])
    } catch (error) {
      callback(commonUtils.formatErrorMsg(error), '')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // api.recordError(`youdao_${commonUtils.formatErrorMsg(error)}`, { useType: 'image' } as any)
    }

    this.updateUsageInfoThrottleCal()
  }

  private async checkVersionAndClearCacheIfNeeded() {
    try {
      // 获取存储的版本号
      const storedVersion = await this.getStoredVersion()

      // 如果版本不同且需要清除缓存
      if (storedVersion !== this.APP_VERSION && this.CLEAR_CACHE_ON_UPDATE) {
        console.log(`版本已从 ${storedVersion} 更新到 ${this.APP_VERSION}，正在清除缓存...`)
        await this.clearAllCaches()
        // 更新存储的版本号
        await this.storeCurrentVersion()
      }
    } catch (error) {
      console.error('检查版本并清除缓存时出错:', error)
    }
  }

  // 获取存储的版本号
  private async getStoredVersion(): Promise<string | null> {
    return new Promise((resolve) => {
      if (!this.db) {
        resolve(null)
        return
      }

      try {
        const transaction = this.db.transaction(['translatedTextCacheMap'], 'readonly')
        const objectStore = transaction.objectStore('translatedTextCacheMap')
        const request = objectStore.get(this.APP_VERSION_KEY)

        request.onsuccess = () => {
          if (request.result) {
            resolve(request.result.version)
          } else {
            resolve(null)
          }
        }

        request.onerror = () => {
          console.error('获取存储版本失败')
          resolve(null)
        }
      } catch (error) {
        console.error('获取存储版本出错:', error)
        resolve(null)
      }
    })
  }

  // 存储当前版本号
  private async storeCurrentVersion() {
    return new Promise<void>((resolve) => {
      if (!this.db) {
        resolve()
        return
      }

      try {
        const transaction = this.db.transaction(['translatedTextCacheMap'], 'readwrite')
        const objectStore = transaction.objectStore('translatedTextCacheMap')
        const request = objectStore.put({
          key: this.APP_VERSION_KEY,
          version: this.APP_VERSION,
        })

        request.onsuccess = () => {
          console.log(`成功存储应用版本 ${this.APP_VERSION}`)
          resolve()
        }

        request.onerror = () => {
          console.error('存储应用版本失败')
          resolve()
        }
      } catch (error) {
        console.error('存储应用版本出错:', error)
        resolve()
      }
    })
  }

  // 清除所有缓存
  private async clearAllCaches() {
    await this.clearTextCache()
    await this.clearImageCache()
    console.log('所有缓存数据已清除')
  }

  // 清除文本翻译缓存
  private async clearTextCache(): Promise<void> {
    return new Promise((resolve) => {
      if (!this.db) {
        resolve()
        return
      }

      try {
        const transaction = this.db.transaction(['translatedTextCacheMap'], 'readwrite')
        const objectStore = transaction.objectStore('translatedTextCacheMap')
        const request = objectStore.clear()

        request.onsuccess = () => {
          this.translatedTextCacheMap = {}
          console.log('文本翻译缓存已清除')
          resolve()
        }

        request.onerror = () => {
          console.error('清除文本翻译缓存失败')
          resolve()
        }
      } catch (error) {
        console.error('清除文本翻译缓存出错:', error)
        resolve()
      }
    })
  }

  // 清除图片翻译缓存
  private async clearImageCache(): Promise<void> {
    return new Promise((resolve) => {
      if (!this.imgDb) {
        resolve()
        return
      }

      try {
        const transaction = this.imgDb.transaction(['translatedImageCacheMap'], 'readwrite')
        const objectStore = transaction.objectStore('translatedImageCacheMap')
        const request = objectStore.clear()

        request.onsuccess = () => {
          this.translatedImageCacheMap = {}
          console.log('图片翻译缓存已清除')
          resolve()
        }

        request.onerror = () => {
          console.error('清除图片翻译缓存失败')
          resolve()
        }
      } catch (error) {
        console.error('清除图片翻译缓存出错:', error)
        resolve()
      }
    })
  }

  // 公共方法，用于手动清除所有缓存
  public async clearAllTranslateCache() {
    await this.clearAllCaches()
    await this.storeCurrentVersion()
  }
}

export const translateService = new TranslateService()
